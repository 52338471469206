import {
  Container,
  Stack,
  Typography,
  Grid,
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from "@mui/material";
import Page from "src/components/Page";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { useState } from "react";
import { filter } from "lodash";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import { useUserContext } from "../context/appContext";
import React, { useEffect } from "react";
import { UserListHead, UserListToolbar } from "../components/_dashboard/user";
import ChartModal from "src/components/charts/ChartModal";
import { Box, styled } from "@mui/system";
import TablePaginationUnstyled from "@mui/base/TablePaginationUnstyled";


import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


//////////////////////////////////
import axios from 'axios';

//

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "waterLevel", label: "Tank", alignRight: false },
  { id: "motor", label: "Motor", alignRight: false },
  { id: "source", label: "Source", alignRight: false },
  { id: "runTime", label: "Run Time", alignRight: false },
  { id: "recentUpdate", label: "Last Update", alignRight: false },
  { id: "viewmore", label: " ", alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ContractorWise() {
  // const { user, firestoreSnapshot, getDataFromFirebase } = useUserContext();

  // useEffect(() => {
  //   if (firestoreSnapshot.length === 0 && user) {
  //     getDataFromFirebase();
  //   }
  // }, [firestoreSnapshot, user, getDataFromFirebase]);
  // const Contractor = [
  //   ...new Set(firestoreSnapshot.map((data) => data.contractorName)),
  // ];




 //////////api calling////////////////////
const { user,cookies} = useUserContext();

 const [contractorInfo,setContractorInfo]=useState([]);
 const [selectContractor, setSelectContractor] = useState('');
 const[villageinfo,setVillageInfo]=useState([]);
 

 const getAllContractor= async ()=>{
   await axios.get(`http://51.158.109.251:9001/api/villagerecord/contractorwise`,{params:{userId: cookies.get('userId')}})
     .then(res => {
        const ContractorData = res.data;
   
        setContractorInfo(ContractorData)
       })
       .catch((err) => console.log(err));
 }

 const getAllVillageByContractor= async ()=>{

  if(selectContractor=='')
  {
    await axios.get(`http://51.158.109.251:9001/api/villagerecord/`,{params:{userId: cookies.get('userId')}})
      .then(res => {
         const villageData = res.data;
      
         setVillageInfo(villageData)
        })
        .catch((err) => console.log(err));
    }
    
    else{
      await axios.get(`http://51.158.109.251:9001/api/villagerecord/contractorwisevillage`, { params: { cName:  selectContractor,userId: cookies.get('userId')} })
      .then(res => {
        const villageData = res.data;
        
        setVillageInfo(villageData);
        })
        .catch((err) => console.log(err));
    }

      

}

const [items, setItems] = useState(villageinfo);

useEffect(() => {
    if (contractorInfo.length === 0  ) {
      getAllContractor();
    }
    if(villageinfo.length0 ===0)
    {
      getAllVillageByContractor();
    }
    setItems(villageinfo);
  }, [user,getAllVillageByContractor]);

useEffect(()=>{
  if(cookies.get('userId'))
  {
    getAllVillageByContractor();
  }
  setItems(villageinfo);

},[selectContractor])

  const Contractor = [
    ...new Set(contractorInfo.map((data) => data.contractorName)),
  ];




 // const gridWidth = Math.max(4, 12 / Contractor.length);
  // const [selectContractor, setSelectContractor] = useState("");
  // const [items, setItems] = useState(firestoreSnapshot);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const [rowsPerPage, setRowsPerPage] = useState(5);

  
  let motor = false;

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - villageinfo.length)
      : 0;

  let filteredUsers = applySortFilter(
    items,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  // const handleChange = (event, contName) => {
  //   let updatedItems = firestoreSnapshot;
  //   if (contName) {
  //     updatedItems = firestoreSnapshot.filter((curElem) => {
  //       return curElem.contractorName === contName;
  //     });
  //   }

  //   setSelectContractor(contName);

  //   setItems(updatedItems);
  // };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  function dateGenerate(e) {
    //var t = new Date(e);

   // return t.toLocaleDateString() + " | " + t.toLocaleTimeString();
   const str=e.split(' ')[0]+" | "+e.split(' ')[1]+" "+e.split(' ')[2];
   if(str==='Invalid | Date undefined')
     return 'No Record Found'
   else
     return str;
  }

  //table pagination-----------
  const blue = {
    200: "#A5D8FF",
    400: "#3399FF",
  };

  const grey = {
    50: "#F3F6F9",
    100: "#E7EBF0",
    200: "#E0E3E7",
    300: "#CDD2D7",
    400: "#B2BAC2",
    500: "#A0AAB4",
    600: "#6F7E8C",
    700: "#3E5060",
    800: "#2D3843",
    900: "#1A2027",
  };
  const CustomTablePagination = styled(TablePaginationUnstyled)(
    ({ theme }) => `
  & .MuiTablePaginationUnstyled-spacer {
    display: none;
  }
  & .MuiTablePaginationUnstyled-toolbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 300px) {
      flex-direction: row;
      align-items: center;
    }
  }
  & .MuiTablePaginationUnstyled-selectLabel {
    margin: 20px;
  }
  & .MuiTablePaginationUnstyled-select {
    padding: 2px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    border-radius: 50px;
    background-color: transparent;
    &:hover {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    }
    &:focus {
      outline: 1px solid ${
        theme.palette.mode === "dark" ? blue[400] : blue[200]
      };
    }
  }
  & .MuiTablePaginationUnstyled-displayedRows {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }
  & .MuiTablePaginationUnstyled-actions {
    padding: 2px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    border-radius: 50px;
    text-align: center;
  }
  & .MuiTablePaginationUnstyled-actions > button {
    margin: 0 8px;
    border: transparent;
    border-radius: 2px;
    background-color: transparent;
    &:hover {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    }
    &:focus {
      outline: 1px solid ${
        theme.palette.mode === "dark" ? blue[400] : blue[200]
      };
    }
  }
  `
  );

 
  //--------

  
  const handleChangeModal = (event) => {


    setSelectContractor(event.target.value);

    setItems(villageinfo);
  };

 


  ///////

  return (
    <Page title="Contractor Wise">
      <Container>
      <Box component="form" >
                <FormControl sx={{minWidth: '40%' }}>
                  <InputLabel htmlFor="demo-dialog-native">Select Contractor</InputLabel>
                  <Select
                    native
                    value={selectContractor}
                    onChange={handleChangeModal}
                    input={
                      <OutlinedInput label="Contractor" id="demo-dialog-native" />
                    }
                  >
                    <option aria-label="None" value="" />
                    {
                     
                    Contractor.map((item)=>(
                      <option value={item} key={item}>{item.toUpperCase()}</option>
                    ))}
                  </Select>
                </FormControl>
              </Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
         mt={-6}
          ml={'60%'}
        >
          <Typography variant="h4" gutterBottom>
            Automation : Contractor Wise
          </Typography>
        </Stack>
        
        {/* <div>
          <Button onClick={handleClickOpenModal}  variant='contained' sx={{m:'1%'}}>Click For Open Contractor List</Button>
          <Dialog disableEscapeKeyDown open={open} onClose={handleCloseModal}>
            <DialogTitle>Select Contractor</DialogTitle>
            <DialogContent>
              <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
                <FormControl sx={{ m: 1, minWidth: '80%' }}>
                  <InputLabel htmlFor="demo-dialog-native">Contractor</InputLabel>
                  <Select
                    native
                    onChange={handleChangeModal}
                    input={
                      <OutlinedInput label="Contractor" id="demo-dialog-native" />
                    }
                  >
                    <option aria-label="None" value="" />
                    {
                     
                    Contractor.map((item)=>(
                      <option value={item}>{item.toUpperCase()}</option>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal}>Cancel</Button>
              <Button onClick={handleCloseModal}>Ok</Button>
            </DialogActions>
          </Dialog>
        </div> */}
        {/* <Grid
          container
          spacing={1}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md:4 }}
          margin={"10px"}
        >
          {Contractor.slice(
            (contractorPage-1)* constractorPerPage,
            (contractorPage-1)* constractorPerPage + constractorPerPage
          )
          .map((item) => ( 
            <Grid item xs={12} sm={6} md={gridWidth} id={item}>
              <ToggleButtonGroup
                color="primary"
                size="large"
                variant="contained"
                value={selectContractor}
                exclusive
                fullWidth
                onChange={hadnleChange}
              >
                <ToggleButton value={item}>{item.toUpperCase()}</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          ))}
        </Grid>
        { (Contractor.length>0)?
          <Box
            sx={{
              margin:5
            }}
          >
             <Pagination
              count={Math.ceil(Contractor.length / 6)}
              color="primary"
              page={contractorPage}
              onChange={handleChangeConstructor}
            /> 
          </Box>
          :' '} */}

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={4}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        dId,
                        name,
                        waterLevel,
                        mState,
                        deployed,
                        totalRunningTime,
                        recentUpdate,

                  
                      } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;
                      if (mState !== "0") {
                        motor = true;
                      } else {
                        motor = false;
                      }
                      return (
                        <TableRow
                          hover
                          key={dId}
                          tabIndex={1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox"></TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">{waterLevel}</TableCell>

                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={motor ? "success" : "error"}
                            >
                              {motor ? "ON" : "OFF"}
                            </Label>
                          </TableCell>

                          <TableCell align="left">{deployed}</TableCell>
                          <TableCell align="left">{totalRunningTime} Min.</TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Typography variant="subtitle2">
                              {dateGenerate(recentUpdate)}
                            </Typography>
                          </TableCell>

                          <TableCell align="left">
                            <ChartModal id={dId} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <CustomTablePagination
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={3}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            componentsProps={{
              select: {
                "aria-label": "rows per page",
              },
              actions: {
                showFirstButton: true,
                showLastButton: true,
              },
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
