import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Typography from "@mui/material/Typography";
import { AppWebsiteVisits, AppConversionRates } from "../_dashboard/app";
import SchedulerGraph from "../Graphs/SchedularGraph";
import { CanvasJSChart } from "canvasjs-react-charts";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import { useState, useEffect } from "react";
import { useUserContext } from "src/context/appContext";

import axios from "axios";
import { typography } from "@mui/system";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";

import { CircularProgress } from "@mui/material";

import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import Stack from "@mui/material/Stack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};
const btn = {
  left: "30%",
};

Date.prototype.sameDay = function (d) {
  return (
    this.getFullYear() === d.getFullYear() &&
    this.getDate() === d.getDate() &&
    this.getMonth() === d.getMonth()
  );
};

export default function ChartModal(props) {
  const [open, setOpen] = React.useState(false);

  const { user,cookies } = useUserContext();

  const [deviceinfo, setDevideinfo] = useState([]);
  const [todayData, setTodayData] = useState([]);

  const [date, setDate] = React.useState(new Date());

  function compare(a, b) {
    if (a.x < b.x) {
      return -1;
    }
    if (a.x > b.x) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    let data = [];
    if (deviceinfo.waterLevelData) {
      let prevData = false;
      let lastWLevel = 0;

      for (let i = 0; i < deviceinfo.waterLevelData.length; i++) {
        const x = deviceinfo.waterLevelData[i].split(" ");
        const temp = x[0].split("-").reverse().join("/");

        let tempTime = x[1].split(":");
        const tempDate = new Date(temp);
        tempDate.setHours(tempTime[0]);
        tempDate.setMinutes(tempTime[1]);
        tempDate.setSeconds(tempTime[2]);

        // if(tempDate<date)
        // {
        //     prevData=[...prevData,parseInt(x[2])];
        //     lastWLevel= parseInt(x[2])
        // }

        if (tempDate.sameDay(date)) {
          lastWLevel = parseInt(x[2]);
          prevData=true;

          data = [
            ...data,
            {
              x:
                Number.parseInt(tempTime[0]) +
                Number((tempTime[1] / 60).toFixed(1)),
              y: parseInt(x[2]),
            },
          ];
        } else if(!prevData) {
          lastWLevel = parseInt(x[2]);
          data = [
            ...data,
            {
              x: 0,
              y: parseInt(x[2]),
            },
          ];
        }
      }

      if (date.sameDay(new Date())) {
      
        data = [
          ...data,
          {
            x:
              new Date().getHours() +
              Number((new Date().getMinutes() / 60).toFixed(1)),
            y: lastWLevel,
          },
        ];
      }
      if (data.length == 0) {
        data = [...data, { x: 0, y: 0 }];
      }
      data.sort(compare);
      setTodayData(data);
      //console.log(data);
      // if (data.length == 0) {
      //   setTodayData([{ x: 0, y: 0 }]);
      // } else {
      //   setTodayData(data);
      // }
    }
  }, [deviceinfo, date]);
  const getDeviceData = async () => {
    await axios
      .get(`http://51.158.109.251:9001/api/villagerecord/graph`, {
        params: { dId: props.id },
      })
      .then((res) => {
        const villageData = res.data;

        setDevideinfo(villageData[0]);
      })
      .catch((err) => console.log(err));
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setDate(new Date());
    setOpen(false);
  };

  const [name, setName] = useState("");
  const [waterLevel, setWaterLevel] = useState("");
  const [source, setSource] = useState("");
  const [tank, setTank] = useState("");
  const [population, setPopulation] = useState("");
  const [lpcd, setLpcd] = useState("");
  const [contractor, setContractor] = useState("");
  const [runTime, setRunTime] = useState("");
  const [operator, setOperator] = useState("");

  useEffect(() => {
    if (deviceinfo.length == 0 && open == true) {
      getDeviceData();
    }
    setName(deviceinfo.name);
    setRunTime(deviceinfo.totalRunningTime);
    setWaterLevel(deviceinfo.waterLevel);
    setLpcd(
      Number(
        (
          (deviceinfo.totalRunningTime * deviceinfo.lpm) /
          deviceinfo.population
        ).toFixed(2)
      )
    );
    setOperator(
      deviceinfo.operatorName + "(" + deviceinfo.operatorMobile + ")"
    );
    setTank(deviceinfo.boringType);
    setContractor.apply(deviceinfo.contractorName);
    setPopulation(deviceinfo.population);
    setSource(deviceinfo.deployed);
    const intervalId = setInterval(() => {
      if (user && open == true) {
        getDeviceData();
      }
    }, 6000000);
  }, [deviceinfo, user, open]);

  const options = {
    height: 250,
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2", // "light1", "dark1", "dark2"
    axisY: {
      title: "Water Level",
      suffix: "%",
      maximum: 100,
      interval: 25,
    },
    axisX: {
      title: "Time",
      maximum: 24,
      minimum: 0,
      interval: 1,
      intervalType: "hour",
    },
    data: [
      {
        type: "line",
        toolTipContent: "{y}%",
        dataPoints: todayData,
      },
    ],
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <div>
      <Button onClick={handleOpen} variant="contained">
        View More
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <HighlightOffIcon
              onClick={handleClose}
              fontSize="large"
              color="error"
              sx={{ ml: "99%", mt: "-5%" }}
            />
            <Paper
              sx={{
                margin: "auto",
                maxWidth: "100%",
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark" ? "#1A2027" : "#fff",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={8} sx>
                  <SchedulerGraph id={props.id} data={deviceinfo} />
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    margin: "1",
                    maxWidth: "100%",
                  }}
                >
                  <Grid item xs container direction="column" spacing={0}>
                    <Grid item xs>
                      <Typography gutterBottom variant="h6" component="div">
                        Additional Information
                      </Typography>

                      <List
                        sx={{
                          width: "100%",
                          bgcolor: "background.paper",
                          overflow: "auto",
                          maxHeight: 180,
                        }}
                      >
                        <ListItem alignItems="flex-start">
                          <ListItemText secondary={`Village Name : ${name}`} />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        <ListItem alignItems="flex-start">
                          <ListItemText
                            secondary={`Current Water Level : ${waterLevel}%`}
                          />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        <ListItem alignItems="flex-start">
                          <ListItemText secondary={`LPCD : ${lpcd}`} />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        <ListItem alignItems="flex-start">
                          <ListItemText
                            secondary={`Run Time : ${runTime} Min.`}
                          />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        <ListItem alignItems="flex-start">
                          <ListItemText secondary={`Sources : ${source}`} />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        <ListItem alignItems="flex-start">
                          <ListItemText secondary={`Tank : ${tank}`} />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        <ListItem alignItems="flex-start">
                          <ListItemText
                            secondary={`Population : ${population}`}
                          />
                        </ListItem>

                        <Divider variant="inset" component="li" />
                        <ListItem alignItems="flex-start">
                          <ListItemText secondary={`Operator : ${operator}`} />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        <ListItem alignItems="flex-start">
                          <ListItemText
                            seconda
                            ry={`Contractor :  ${contractor}`}
                          />
                        </ListItem>
                      </List>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={0} width={200} marginLeft="55%">
                          <DatePicker
                            inputFormat="dd/MM/yyyy"
                            views={["day"]}
                            label="select date"
                            value={date}
                            height={"100"}
                            disableFuture={true}
                            onChange={(newValue) => {
                              setDate(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                {...params}
                                helperText={null}
                              />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                      {/* <LineChart data={deviceinfo.waterLevelData}/>  */}
                      {/* {console.log("data ->   ",todayData)} */}
                      {/*                                                                                                                                                                                                                        */}
                      {todayData.length ? (
                        <CanvasJSChart options={options} maxHeight={200} />
                      ) : (
                        // <CanvasJSChart options = {{...options,data:{...options.data[0],
                        //   dataPoints:[...todayData],

                        //                         }}}/>:
                        <CircularProgress />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
