import { filter } from "lodash";

import { useState,useEffect } from "react";

import { useUserContext } from "../context/appContext";
import * as React from 'react';

// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
} from "@mui/material";
import { styled } from '@mui/system';
import TablePaginationUnstyled from '@mui/base/TablePaginationUnstyled';

// components
import Page from "../components/Page";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,

} from "../components/_dashboard/user";
import ChartModal from "src/components/charts/ChartModal";
//

//////////////////////////////api/////////////////////
import axios from 'axios';






// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "waterLevel", label: "Tank", alignRight: false },
  { id: "motor", label: "Motor", alignRight: false },
  { id: "source", label: "Source", alignRight: false },
  { id: "runTime", label: "Run Time", alignRight: false },
  { id: "recentUpdate", label: "Last Update", alignRight: false },
  { id: "viewmore", label: "More Info", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

//table pagination-----------
const blue = {
  200: '#A5D8FF',
  400: '#3399FF',
};

const grey = {
  50: '#F3F6F9',
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};
const CustomTablePagination = styled(TablePaginationUnstyled)(
  ({ theme }) => `
  & .MuiTablePaginationUnstyled-spacer {
    display: none;
  }
  & .MuiTablePaginationUnstyled-toolbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 300px) {
      flex-direction: row;
      align-items: center;
    }
  }
  & .MuiTablePaginationUnstyled-selectLabel {
    margin: 20px;
  }
  & .MuiTablePaginationUnstyled-select {
    padding: 2px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    border-radius: 50px;
    background-color: transparent;
    &:hover {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    }
    &:focus {
      outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
    }
  }
  & .MuiTablePaginationUnstyled-displayedRows {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }
  & .MuiTablePaginationUnstyled-actions {
    padding: 2px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    border-radius: 50px;
    text-align: center;
  }
  & .MuiTablePaginationUnstyled-actions > button {
    margin: 0 8px;
    border: transparent;
    border-radius: 2px;
    background-color: transparent;
    &:hover {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    }
    &:focus {
      outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
    }
  }
  `,
);


//----------------

export default function User() {

  const block=window.location.pathname.split("/")[3]

  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [area, setArea] = useState("");



  // const { user,firestoreSnapshot, getDataFromFirebase } = useUserContext();

  // useEffect(() => {
  //   if (firestoreSnapshot.length === 0 && user) {
  //     getDataFromFirebase();
  //   }
   
    
  // }, [firestoreSnapshot,user,getDataFromFirebase]);

  //////////////////////////////api calling////////////////////
  const { user,cookies} = useUserContext();

  // const[blockinfo,setBlockInfo]=useState([]);
  const[villageinfo,setVillageInfo]=useState([]);
  // const[block,setBlock]=useState('');
  // const getAllBlock= async ()=>{
  //   await axios.get(`http://51.158.109.251:9001/api/villageinfo/blockwiseblock`, { params: { block:  block} })
  //     .then(res => {
  //        const blockData = res.data;
        
  //        setBlockInfo(blockData)
  //       })
  //       .catch((err) => console.log(err));
  // }

  const getAllVillageByBlock= async ()=>{
    await axios.get(`http://51.158.109.251:9001/api/villagerecord/blockwisevillage`, { params: { bName:  block,userId: cookies.get('userId')} })
      .then(res => {
         const villageData = res.data;
       console.log(villageData)
         setVillageInfo(villageData);
        })
        .catch((err) => console.log(err));
  }

  const [items, setItems] = useState(villageinfo);
useEffect(() => {
   
    if(villageinfo.length==0 && cookies.get('userId'))
    {
      getAllVillageByBlock();
    }
 setItems(villageinfo);
  }, [user,villageinfo,getAllVillageByBlock]);

  useEffect(()=>{
    if(cookies.get('userId'))
    {
      getAllVillageByBlock();
    }
  },[block]);

  
  let motor = false;
  
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - villageinfo.length) : 0;

  
  let filteredUsers=[...new Set(villageinfo.map((data) => data))]

  
  

  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - firestoreSnapshot.length) : 0;
  // const [items, setItems] = useState(firestoreSnapshot);
  // let filteredUsers=[...new Set(firestoreSnapshot.map((data) => data))]
  
    filteredUsers = applySortFilter(
      items,
      getComparator(order, orderBy),
      filterName
    );
 
  
  const isUserNotFound = filteredUsers.length === 0;

  // const City = [...new Set(firestoreSnapshot.map((data) => data.block))];
  // const City = [...new Set(blockinfo.map((data) => data.blockName))];
  // const gridWidth = Math.max(3, 12 / City.length);
  // const handleChange = (event, area) => {
  //   let updatedItems = firestoreSnapshot;
  //   if (area) {
  //     updatedItems = firestoreSnapshot.filter((curElem) => {
  //       return curElem.block === area.toLowerCase();
  //     });
  //   }

  //   setArea(area);

  //   setBlock(area);

  // };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  function dateGenerate(e){
      //var t=new Date(e);
      //return t.toLocaleDateString()+" | "+t.toLocaleTimeString()
      const str=e.split(' ')[0]+" | "+e.split(' ')[1]+" "+e.split(' ')[2];
      if(str==='Invalid | Date undefined')
        return 'No Record Found'
      else
        return str;
  }

  return (
    <Page title="Block Wise">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={0}
        >
          <Typography variant="h6" gutterBottom>
            Automation : Block Wise :- {block.toUpperCase()}
          </Typography>
          
        </Stack>
        {/* <Grid
          container
          spacing={3}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {City.map((item) => (
            <Grid item xs={12} sm={6} md={gridWidth} id={item}>
              <ToggleButtonGroup
                color="primary"
                size="large"
                variant="contained"
                value={area}
                exclusive
                fullWidth
                onChange={handleChange}
              >
                <ToggleButton value={item}>{item.toUpperCase()}</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          ))}
        </Grid> */}

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={4}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        dId,
                        name,
                        waterLevel,
                        mState,
                        deployed,
                        totalRunningTime,
                        recentUpdate,

                  
                      } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;
                      if (mState !== "0") {
                        motor = true;
                        
                      } else {
                        motor = false;
                        
                      }
           
                      return (
                        <TableRow
                          hover
                          key={dId}
                          tabIndex={1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox"></TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">{waterLevel}</TableCell>

                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={motor ? "success" : "error"}
                            >
                              {motor ? "ON" : "OFF"}
                            </Label>
                          </TableCell>
                          <TableCell align="left">{deployed}</TableCell>
                          <TableCell align="left">{totalRunningTime} Min.</TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Typography variant="subtitle2">
                             { dateGenerate(recentUpdate)}
                            </Typography>
                          </TableCell>

                          <TableCell align="left">
                            <ChartModal  id={dId}/>
                            
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <CustomTablePagination
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={3}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            componentsProps={{
              select: {
                'aria-label': 'rows per page',
              },
              actions: {
                showFirstButton: true,
                showLastButton: true,
              },
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
