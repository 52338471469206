// material
import { Box, Grid, Container, Typography } from "@mui/material";
// components
import Page from "../components/Page";
import {
  AppCurrentVisits,
  AppWebsiteVisits,
} from "../components/_dashboard/app";
import { Avatar, Card, CardContent, LinearProgress } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import MoneyIcon from "@mui/icons-material/Money";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import PeopleIcon from "@mui/icons-material/PeopleOutlined";
import InsertChartIcon from "@mui/icons-material/InsertChartOutlined";
import { useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useUserContext } from "src/context/appContext";
// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { cookies } = useUserContext();
  const navigate = useNavigate();
  useEffect(() => {
  
    if(!cookies.get('userId'))
    {
      navigate("/login", { replace: true });
    }
  },[cookies]);
  return (
    <Page title="Dashboard | PHED">
      <Container maxWidth="xl">
        {/* <Grid container spacing={3}>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Card sx={{ height: "100%" }}> 
              <CardContent>
                <Grid
                  container
                  spacing={3}
                  sx={{ justifyContent: "space-between" }}
                >
                  <Grid item>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="overline"
                    >
                      TOTAL VILLAGES
                    </Typography>
                    <Typography color="textPrimary" variant="h4">
                      1,6k
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Avatar
                      sx={{
                        backgroundColor: "success.main",
                        height: 56,
                        width: 56,
                      }}
                    >
                      <PeopleIcon />
                    </Avatar>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    pt: 2,
                  }}
                >
                  <ArrowUpwardIcon color="success" />
                  <Typography
                    variant="body2"
                    sx={{
                      mr: 1,
                    }}
                  >
                    16%
                  </Typography>
                  <Typography color="textSecondary" variant="caption">
                    Since last month
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Card sx={{ height: "100%" }}>
              <CardContent>
                <Grid
                  container
                  spacing={3}
                  sx={{ justifyContent: "space-between" }}
                >
                  <Grid item>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="overline"
                    >
                      INSTALLATION PROGRESS
                    </Typography>
                    <Typography color="textPrimary" variant="h4">
                      75.5%
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Avatar
                      sx={{
                        backgroundColor: "warning.main",
                        height: 56,
                        width: 56,
                      }}
                    >
                      <InsertChartIcon />
                    </Avatar>
                  </Grid>
                </Grid>
                <Box sx={{ pt: 3 }}>
                  <LinearProgress value={75.5} variant="determinate" />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Card sx={{ height: "100%" }}>
              <CardContent>
                <Grid
                  container
                  spacing={3}
                  sx={{ justifyContent: "space-between" }}
                >
                  <Grid item>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="overline"
                    >
                      BUDGET
                    </Typography>
                    <Typography color="textPrimary" variant="h4">
                      $24k
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Avatar
                      sx={{
                        backgroundColor: "error.main",
                        height: 56,
                        width: 56,
                      }}
                    >
                      <MoneyIcon />
                    </Avatar>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    pt: 2,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ArrowDownwardIcon color="error" />
                  <Typography
                    color="error"
                    sx={{
                      mr: 1,
                    }}
                    variant="body2"
                  >
                    12%
                  </Typography>
                  <Typography color="textSecondary" variant="caption">
                    Since last month
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
         
        </Grid> */}
        
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
         
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
