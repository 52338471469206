import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { useUserContext } from "src/context/appContext";
import { useState, useEffect } from "react";
import { useTheme, styled } from "@mui/material/styles";
import { Card, CardHeader } from "@mui/material";
// utils
import { fNumber } from "../../../utils/formatNumber";
//
import { BaseOptionChart } from "../../charts";




import axios from 'axios';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    position: "relative !important",
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

export default function AppCurrentVisits() {
  const theme = useTheme();
  // const { user, firestoreSnapshot, getDataFromFirebase } = useUserContext();

  // let tempOht = 0;
  // let tempSumpwell = 0;
  // let tempDirect = 0;
  // const [oht, setOht] = useState(0);
  // const [sumpwell, setSumpwell] = useState(0);
  // const [direct, setDirect] = useState(0);
  // useEffect(() => {
  //   if (firestoreSnapshot.length === 0 && user) {
  //     getDataFromFirebase();
  //   }
  //   boringTypeSorting();
  // }, [firestoreSnapshot, user, getDataFromFirebase]);

  // function boringTypeSorting() {
  //   tempOht = firestoreSnapshot.filter((curElem) => {
  //     return curElem.boringType === "oht";
  //   });
  //   setOht(tempOht.length);

  //   tempSumpwell = firestoreSnapshot.filter((curElem) => {
  //     return curElem.boringType === "sumpwell";
  //   });
  //   setSumpwell(tempSumpwell.length);

  //   tempDirect = firestoreSnapshot.filter((curElem) => {
  //     return curElem.boringType === "direct";
  //   });
  //   setDirect(tempDirect.length);
  // }

/////////////////////////////api calling//////////////////////

const[villageinfo,setVillageinfo]=useState([]);

const { cookies} = useUserContext();
  const getDataFromApi= async ()=>{
    await axios.get(`http://51.158.109.251:9001/api/villagerecord/`, {params: { userId:cookies.get('userId') }})
      .then(res => {
         const villageData = res.data;
         setVillageinfo(villageData)
        })
        .catch((err) => console.log(err));
  }
  

  let tempOht = 0;
  let tempSumpwell = 0;
  let tempDirect = 0;
  const [oht, setOht] = useState(0);
  const [sumpwell, setSumpwell] = useState(0);
  const [direct, setDirect] = useState(0);
  useEffect(() => {
    if (villageinfo.length === 0 ) {
      getDataFromApi();
    }
    boringTypeSorting();
  }, [villageinfo, getDataFromApi]);

  function boringTypeSorting() {
    tempOht = villageinfo.filter((curElem) => {
      return curElem.boringType === "oht";
    });
    setOht(tempOht.length);

    tempSumpwell = villageinfo.filter((curElem) => {
      return curElem.boringType === "sumpwell";
    });
    setSumpwell(tempSumpwell.length);

    tempDirect = villageinfo.filter((curElem) => {
      return curElem.boringType === "direct";
    });
    setDirect(tempDirect.length);
  }




  const CHART_DATA = [oht, sumpwell, direct, 600 - getDataFromApi.length];
  const chartOptions = merge(BaseOptionChart(), {
    colors: [
      theme.palette.primary.main,
      theme.palette.info.main,
      theme.palette.warning.main,
      theme.palette.grey[400],
    ],
    labels: ["OHT", "SUMPWELL", "DIRECT", "NONE"],
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: "center" },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  return (
    <Card>
      <CardHeader title="Current Status of Automation" />
      <ChartWrapperStyle dir="ltr">
        <ReactApexChart
          type="pie"
          series={CHART_DATA}
          options={chartOptions}
          height={280}
        />
      </ChartWrapperStyle>
    </Card>
  );
}
