import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Icon } from '@iconify/react';
import Typography from "@mui/material/Typography";
import { Container, Stack } from "@mui/material";
import { LoginForm } from "./authentication/login";
import { useUserContext } from "../context/appContext";
import { Link as RouterLink, useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function TransitionsModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { user, logoutUser,cookies } = useUserContext();
  const navigate = useNavigate();
  // React.useEffect(() => {
  
  //   if(!cookies.get('userId'))
  //   {
  //     navigate("/login", { replace: true });
  //   }
  // },[logoutUser]);
  return (
    <div>
   
        <Button
          fullWidth
          //startIcon={LogoutIcon}
          color="inherit"
          variant="outlined"
          onClick={() => {
            logoutUser();
            cookies.remove('userId');
            window.location.reload();
          }}
        >
          <Icon icon="ls:login" style={{marginRight: 10}}  />
          LogOut
        </Button>
    
   
    
      
      {/* <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Container maxWidth="sm">
              <Stack sx={{ mb: 5 }}>
                <Typography variant="h4" gutterBottom>
                  Login
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  Enter your details below.
                </Typography>
              </Stack>

              <LoginForm />
            </Container>
          </Box>
        </Fade>
      </Modal> */}
    </div>
  );
}