// material

// components
import Page from "../components/Page";



import { useState } from "react";


import { useUserContext } from "src/context/appContext";
import React, { useEffect } from "react";



// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
} from "@mui/material";
import { styled } from '@mui/system';
import TablePaginationUnstyled from '@mui/base/TablePaginationUnstyled';


import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,

} from "../components/_dashboard/user";
import ChartModal from "src/components/charts/ChartModal";


import { filter } from "lodash";

///////api

import axios from "axios";
// ----------------------------------------------------------------------
const gridWidth = 6;



// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "waterLevel", label: "Tank", alignRight: false },
  { id: "motor", label: "Motor", alignRight: false },
  { id: "source", label: "Source", alignRight: false },
  { id: "runTime", label: "Run Time", alignRight: false },
  { id: "recentUpdate", label: "Last Update", alignRight: false },
  { id: "viewmore", label: "More Info", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

//table pagination-----------
const blue = {
  200: '#A5D8FF',
  400: '#3399FF',
};

const grey = {
  50: '#F3F6F9',
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};
const CustomTablePagination = styled(TablePaginationUnstyled)(
  ({ theme }) => `
  & .MuiTablePaginationUnstyled-spacer {
    display: none;
  }
  & .MuiTablePaginationUnstyled-toolbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 300px) {
      flex-direction: row;
      align-items: center;
    }
  }
  & .MuiTablePaginationUnstyled-selectLabel {
    margin: 20px;
  }
  & .MuiTablePaginationUnstyled-select {
    padding: 2px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    border-radius: 50px;
    background-color: transparent;
    &:hover {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    }
    &:focus {
      outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
    }
  }
  & .MuiTablePaginationUnstyled-displayedRows {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }
  & .MuiTablePaginationUnstyled-actions {
    padding: 2px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    border-radius: 50px;
    text-align: center;
  }
  & .MuiTablePaginationUnstyled-actions > button {
    margin: 0 8px;
    border: transparent;
    border-radius: 2px;
    background-color: transparent;
    &:hover {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    }
    &:focus {
      outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
    }
  }
  `,
);


export default function Blog() {

  const report=window.location.pathname.split("/")[3]
  const { user ,cookies} = useUserContext();
  const [villageinfo, setVillageinfo] = useState([]);

  const [report1, setReport1] = useState(0);
  const [report2, setReport2] = useState(0);
  const [report3, setReport3] = useState(0);
  const [report4, setReport4] = useState(0);
  const [newVillage, setNewVillage] = useState(0);


  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const[filterData,setFilterData]=useState(villageinfo)

  const getDataFromApi = async () => {
    await axios
      .get(`http://51.158.109.251:9001/api/villagerecord/`,{params: { userId:cookies.get('userId') }})
      .then((res) => {
        const villageData = res.data;
        setVillageinfo(villageData);
        setFilterData(villageData);
      })
      .catch((err) => console.log(err));
  };
  const [selectedVillage, setSelectedVillage] = useState(villageinfo);

  useEffect(() => {
    var c1 = 0;
    var c2 = 0;
    var c3 = 0;
    var c4 = 0;
    var c5=0;
    if (villageinfo.length === 0 ) {
      getDataFromApi();
    }
    villageinfo.map((item) => {
      if (item.updateAgo/1440 > 2) {
        c1++;
      } else if (item.mState === "1") {
        c2++;
      } else if (item.totalRunningTimeForColor < 10) {
        c3++;
      } else {
        c4++;
      }
      if(item.newlyAdded<6)
      {
        c5++;
      }
    });
    setReport4(c1);
    setReport1(c2);
    setReport3(c3);
    setReport2(c4);
    setNewVillage(c5);

    if(report=="newvillage")
    {
      setFilterData(villageinfo.filter((curElem) => {
        return curElem.newlyAdded<6;
       })
       )
    }
    else if(report=="report1")
    {
      
      setFilterData(villageinfo.filter((curElem) => {
         return curElem.mState==="1";
        })
        )
      
    }
    else if(report=="report3")
    {
      setFilterData(villageinfo.filter((curElem) => {
        return curElem.totalRunningTimeForColor<10&&(curElem.updateAgo/1440)<2 && curElem.mState==='0';
      }) )
     
    }
    else if(report=="report4")
    {
      setFilterData(villageinfo.filter((curElem) => {
        return (curElem.updateAgo/1440)>2;
      }) )
      
    }
    else
    {
      setFilterData(villageinfo.filter((curElem) => {
        return curElem.totalRunningTimeForColor>10 && curElem.mState=="0";
      }) )
      
    }
  }, [user, villageinfo,report]);

  let motor = false;
  
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - villageinfo.length) : 0;







  // const handleChange = (event, area) => {
  //   if(area=="1")
  //   {
      
  //     setFilterData(villageinfo.filter((curElem) => {
  //        return curElem.mState==="1";
  //       })
  //       )
      
  //   }
  //   else if(area=="3")
  //   {
  //     setFilterData(villageinfo.filter((curElem) => {
  //       return curElem.totalRunningTime<10&&curElem.updateAgo<3 && curElem.mState==='0';
  //     }) )
     
  //   }
  //   else if(area=="4")
  //   {
  //     setFilterData(villageinfo.filter((curElem) => {
  //       return curElem.updateAgo>2;
  //     }) )
      
  //   }
  //   else
  //   {
  //     setFilterData(villageinfo.filter((curElem) => {
  //       return curElem.totalRunningTime>10 && curElem.mState=="0";
  //     }) )
      
  //   }
  // };



    
  

  let filteredUsers=villageinfo;
  

  filteredUsers = applySortFilter(
    filterData,
    getComparator(order, orderBy),
    filterName
  ); 
 
//console.log(filteredUsers);

  const isUserNotFound = filteredUsers.length === 0;


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  function dateGenerate(e){
      //var t=new Date(e);
     // return t.toLocaleDateString()+" | "+t.toLocaleTimeString()
     const str=e.split(' ')[0]+" | "+e.split(' ')[1]+" "+e.split(' ')[2];
     if(str==='Invalid | Date undefined')
       return 'No Record Found'
     else
       return str;
  }




  return (
    <Page title="Report | PHED">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={0}
        >
          <Typography variant="h5" gutterBottom>
            Reports :-  {report==='newvillage'? `Newly Added  Village (${newVillage})`:''}
            {report==='report1'? `Recently Running Motors   (${report1})`:''}
            {report==='report2'? `Motors Properly Runningin last 24H    (${report2})`:''}
            {report==='report3'? `Running Less Than 10M in last 24 H    (${report3})`:''}
            {report==='report4'? `Recharge required/Bypass/Other Fault    (${report4})`:''}
          </Typography>
        </Stack>
        {/* <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} rowSpacing={3} columns={16}>
            <Grid item xs={7}>
              <Item style={{ color: "black", backgroundColor: "#90ee90" }} >
                <h3>Recently Running Motors</h3> 
                <h5>village : {report1}</h5>
              </Item>
            </Grid>
            <Grid item xs={7}>
              <Item style={{ color: "black", backgroundColor: "#f7f2f2" }}>
                <h3>Motors Properly Runningin last 24H</h3>
                <h5>village : {report2}</h5>
              </Item>
            </Grid>
            <Grid item xs={7}>
              <Item style={{ color: "black", backgroundColor: "#e86464" }} >
                <h3>Running Less Than 10M in last 24 H</h3>
                <h5>village : {report3}</h5>
              </Item>
            </Grid>
            <Grid item xs={7}>
              <Item style={{ color: "black", backgroundColor: "#ffff66" }}>
                <h3>Recharge required/Bypass/Other Fault</h3>
                <h5>village : {report4}</h5>
              </Item>
            </Grid>
          </Grid>
        </Box> */}
        {/* <Grid
          container
          spacing={1}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
        >
          <Grid item xs={12} sm={10} md={gridWidth}>
            <ToggleButtonGroup
              color="primary"
              size="large"
              variant="contained"
              value={"1"}
              exclusive
              fullWidth 
              onChange={handleChange}
            >
              <ToggleButton value={1}  style={{ color: "black", backgroundColor: "#90ee90" }} >
              <Box component="span" sx={{ p: 1}}   ><Typography variant={'h6'}   >Recently Running Motors </Typography></Box>
              
              <Box component="span" sx={{ p: 1, border: '1px ' ,backgroundColor:'white' }} ><Typography variant={'subtitle1'}  align={'right'}>village : {report1}</Typography></Box>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} sm={6} md={gridWidth}>
            <ToggleButtonGroup
              color="primary"
              size="large"
              variant="contained"
              value={'2'}
              exclusive
              fullWidth
              onChange={handleChange}
            >
              <ToggleButton value={2}  style={{ color: "black", backgroundColor: "#f7f2f2" }}>
              <Box component="span" sx={{ p: 1 }}><Typography variant={'h6'}  >Motors Properly Running last 24H</Typography></Box>
              <Box component="span" sx={{ p: 1, border: '1px ' ,backgroundColor:'white'  }}><Typography variant={'subtitle1'}  align={'right'}>village : {report2}</Typography></Box>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} sm={6} md={gridWidth}>
            <ToggleButtonGroup
              color="primary"
              size="large"
              variant="contained"
              value={'3'}
              exclusive
              fullWidth
              onChange={handleChange}
            >
              <ToggleButton value={3} style={{ color: "black", backgroundColor: "#e86464" }}>
              <Box component="span" sx={{ p: 1 }} ><Typography variant={'h6'} >Running Less Than 10M in last 24H</Typography></Box>
              <Box component="span" sx={{ p: 1, border: '1px ' ,backgroundColor:'white' }} ><Typography variant={'subtitle1'}  align={'right'}>village : {report3}</Typography></Box>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} sm={6} md={gridWidth}>
            <ToggleButtonGroup
              color="primary"
              size="large"
              variant="contained"
               value={'4'}
              exclusive
              fullWidth
              onChange={handleChange}
            >
              <ToggleButton value={4} style={{ color: "black", backgroundColor: "#ffff66" }}>
              <Box component="span" sx={{ p: 1 }} ><Typography variant={'h6'} >Recharge required/Bypass/Other Fault</Typography></Box>
              <Box component="span" sx={{ p: 1, border: '1px ' ,backgroundColor:'white' }}><Typography variant={'subtitle1'}  align={'right'}>village : {report4}</Typography></Box>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid> */}

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={4}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        dId,
                        name,
                        waterLevel,
                        mState,
                        deployed,
                        totalRunningTime,
                        recentUpdate
                      } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;
                      if (mState !== "0") {
                        motor = true;
                        
                      } else {
                        motor = false;
                        
                      }
           
                      return (
                        <TableRow
                          hover
                          key={dId}
                          tabIndex={1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox"></TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">{waterLevel}</TableCell>

                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={motor ? "success" : "error"}
                            >
                              {motor ? "ON" : "OFF"}
                            </Label>
                          </TableCell>

                          <TableCell align="left">{deployed}</TableCell>
                          <TableCell align="left">{totalRunningTime} Min.</TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Typography variant="subtitle2">
                             { dateGenerate(recentUpdate)}
                            </Typography>
                          </TableCell>

                          <TableCell align="left">
                            <ChartModal  id={dId}/>
                            
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <CustomTablePagination
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={3}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            componentsProps={{
              select: {
                'aria-label': 'rows per page',
              },
              actions: {
                showFirstButton: true,
                showLastButton: true,
              },
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
