import { createContext, useContext, useState } from "react";
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth, firestoreDatabaseConnection } from "../firebase/firebase";
import { getDocs, collection } from "firebase/firestore";


import axios from 'axios';
import Cookies from 'universal-cookie';

export const UserContext = createContext({});

export const useUserContext = () => {
  return useContext(UserContext);
};

export const UserContextProvider = ({ children }) => {
  const cookies = new Cookies();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [firestoreSnapshot, setFirestoreSnapshot] = useState([]);
  const [isLogin,SetIsLogin]=useState(false);

  // useState(() => {
  //   setLoading(true);
  //   const unsubscribe = onAuthStateChanged(auth, (res) => {
  //     if (res) {
  //       setUser(res);
  //     } else {
  //       setUser(null);
  //     }
  //     setError("");
  //     setLoading(false);
  //   });
  //   return unsubscribe;
  // }, []);

  const signInUser = async (userNumber, password) => {
    // setLoading(true);
    // signInWithEmailAndPassword(auth, email, password)
    //   .then((res) => {})
    //   .catch((err) => setError(err.code))
    //   .finally(() => setLoading(false));

    await axios
      .get(`http://51.158.109.251:9001/api/userlogin`, {
        params: { userNumber: userNumber, password: password }
      })
      .then((res) => {
        const userdata = res.data;
        setUser(userdata);
        cookies.set('userId',res.data[0].userId);


  
      })
      .catch((err) => console.log(err));
  };

  const logoutUser = () => {
    // signOut(auth);
    
    cookies.remove('userId');
    
    
  };

  const forgotPassword = (email) => {
    // return sendPasswordResetEmail(auth, email);
  };

  

  const[blockinfo,setBlockInfo]=useState([]);
  const[villageinfo,setVillageinfo]=useState([]);

  const getAllVillage= async ()=>{
    await axios.get(`http://51.158.109.251:9001/api/villagerecord/`, {params: { userId:cookies.get('userId') }})
      .then(res => {
         const villageData = res.data;
        
         setVillageinfo(villageData)
        })
        .catch((err) => console.log(err));
  }

 const getAllBlock= async ()=>{
    await axios.get(`http://51.158.109.251:9001/api/villagerecord/blockwiseblock`, {params: { userId:cookies.get('userId') }})
      .then(res => {
         const blockData = res.data;
        
         setBlockInfo(blockData)
        })
        .catch((err) => console.log(err));
  }




  

  const contextValue = {
    user,
    loading,
    error,
    signInUser,
    logoutUser,
    villageinfo,
    getAllVillage,
    blockinfo,
    getAllBlock,
    cookies
  };
  

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};