
// material
import { Card, Box, CircularProgress } from '@mui/material';
//
import SearchNotFound from 'src/components/SearchNotFound';
import { filter } from "lodash";
import { useState } from "react";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useUserContext } from 'src/context/appContext'; 
import React, { useEffect } from "react";
import ChartModal from 'src/components/charts/ChartModal';
// material
import Page from "../components/Page";
import Label from 'src/components/Label';

import {

  Table,
  Stack,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  Container,
  TableContainer,
} from "@mui/material";




///////api


import axios from 'axios';



// components
import Scrollbar from 'src/components/Scrollbar';



import {UserListToolbar } from '../components/_dashboard/user';


// ----------------------------------------------------------------------


const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "waterLevel", label: "Tank", alignRight: false },
  { id: "motor", label: "Motor", alignRight: false },
  { id: "source", label: "Source", alignRight: false },
  { id: "runTime", label: "Run Time", alignRight: false },
  { id: "recentUpdate", label: "Last Update", alignRight: false },
  { id: "viewMore", label: "More Info", alignRight: false },
  
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  
  return stabilizedThis.map((el) => el[0]);
}

//////api calling





export default function VillageWise() {

  const { user ,cookies} = useUserContext();

  const [orderBy, setOrderBy] = useState("name");
const [order, setOrder] = useState("asc");
const [filter, setFilter] = React.useState('all');
const[villageinfo,setVillageinfo]=useState([]);

  const getDataFromApi= async ()=>{
    await axios.get(`http://51.158.109.251:9001/api/villagerecord/`,{params: { userId:cookies.get('userId') }})
      .then(res => {
         const villageData = res.data;  
         setVillageinfo(villageData)
        })
        .catch((err) => console.log(err));
  }
  

  let itemsFromApp=villageinfo;
const isUserNotFound = itemsFromApp.length === 0;
const [filteredUsers,setFilteredUsers]=useState(itemsFromApp);
const [selected, setSelected] = useState([]);
const [filterName, setFilterName] = useState("");
itemsFromApp = applySortFilter(
  filteredUsers,
  getComparator(order, orderBy),
  filterName
);

useEffect(()=>{
  if(!cookies.get('userId'))
  {
    
  }
})

useEffect(() => {



  if (villageinfo.length === 0 && cookies.get('userId')) {
    getDataFromApi();
  }
  setFilteredUsers(villageinfo); 


  
}, [villageinfo]);

useEffect(() =>{
  if (filter!=="all") {
    itemsFromApp = villageinfo.filter((curElem) => {
      return curElem.boringType === filter;
    });
  }
  else{
    itemsFromApp = villageinfo.filter((curElem) => {
      return curElem;
    });
  }
  setFilteredUsers(itemsFromApp)

},[filter]);

const handleChange = (event) => {
  setFilter(event.target.value);
};

const handleFilterByName = (event) => {
  setFilterName(event.target.value);
};




///////////////////////////////////////////////////////////////

//   const { user,firestoreSnapshot, getDataFromFirebase } = useUserContext();

// const [orderBy, setOrderBy] = useState("name");
// const [order, setOrder] = useState("asc");
// const [filter, setFilter] = React.useState('all');

 

  

// let items = firestoreSnapshot;
// const isUserNotFound = items.length === 0;
// const [filteredUsers,setFilteredUsers]=useState(items);
// const [selected, setSelected] = useState([]);
// const [filterName, setFilterName] = useState("");
// items = applySortFilter(
//   filteredUsers,
//   getComparator(order, orderBy),
//   filterName
// );

// const handleChange = (event) => {
//   setFilter(event.target.value);
// };
// useEffect(() =>{
//   if (filter!=="all") {
//     items = firestoreSnapshot.filter((curElem) => {
//       return curElem.boringType === filter;
//     });
//   }
//   else{
//     items = firestoreSnapshot.filter((curElem) => {
//       return curElem;
//     });
//   }
//   setFilteredUsers(items)
// },[filter]);



// const handleFilterByName = (event) => {
//   setFilterName(event.target.value);
// };

  // useEffect(() => {
  //   if (firestoreSnapshot.length === 0 && user) {
  //     getDataFromFirebase();
  //   }
  //   setFilteredUsers(firestoreSnapshot);
  // }, [firestoreSnapshot,user,getDataFromFirebase]);

  let motor = false;


  function dateGenerate(e){
    // var t=new Date(e);
    // return t.toLocaleDateString()+" | "+t.toLocaleTimeString()
   
    const str=e.split(' ')[0]+" | "+e.split(' ')[1]+" "+e.split(' ')[2];
    if(str==='Invalid | Date undefined')
      return 'No Record Found'
    else
      return str;
}

  return(
    <Page title="Village Wise">
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
      >
        <Typography variant="h5" gutterBottom>
          Automation : Village Wise
        </Typography>
        
      </Stack>
  <Card >
    <Box 
      sx={{
       display:"flex",
        justifyContent:"space-around"
      }}>
    <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

    
        
        
        <FormControl required sx={{ m: 2.5, minWidth: 120 }} >
        <InputLabel id="demo-simple-select-readonly-label">Select </InputLabel>

        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={filter}
          label="Filter"
          defaultValue={"all"}
          onChange={handleChange}
        >
          <MenuItem value={"all"}>All</MenuItem>
          <MenuItem value={"oht"}>Oht</MenuItem>
          <MenuItem value={"sumpwell"}>Sumpwell</MenuItem>
          <MenuItem value={"direct"}>Direct</MenuItem>
        </Select>
      </FormControl>
       
        
       

    </Box>
    
   
          
          <Scrollbar
          sx={{
            height: "370px",
            "& .simplebar-content": {
              height: "370px",
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
            <TableContainer sx={{ minWidth: 500 }}>
           
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                  
                  {TABLE_HEAD.map((tableCell)=>(
                    <TableCell
                    key={tableCell.id}
                    >
                      {tableCell.label}

                  </TableCell>
                  ))}

                  </TableRow>
                </TableHead>

                <TableBody>
                  {itemsFromApp
                    
                    .map((row) => {
                      const {
                        dId,
                        name,
                        waterLevel,
                        mState,
                        deployed,
                        totalRunningTime,
                        recentUpdate,

                  
                      } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;
                      if (mState !== "0") {
                        motor = true;
                      } else {
                        motor = false;
                      }
                      return (
                        <TableRow
                          hover
                          key={dId}
                          tabIndex={1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          
                        >
                          
                          <TableCell component="th" scope="row">
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">{waterLevel}</TableCell>

                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={motor ? "success" : "error"}
                            >
                              {motor ? "ON" : "OFF"}
                            </Label>
                          </TableCell>

                          <TableCell align="left">{deployed}</TableCell>
                          <TableCell align="left">{totalRunningTime} Min.</TableCell>

                          <TableCell component="th" scope="row" >
                            <Typography variant="subtitle2">
                            { dateGenerate(recentUpdate)}
                            </Typography>
                          </TableCell>
                          
                           <TableCell align="left">
                           <ChartModal  id={dId} data={row}/>
                          </TableCell>

                          
                        </TableRow>
                      );
                    })}
                    
                  
                </TableBody>
                {isUserNotFound && <CircularProgress/>}
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                
                
              </Table>
            </TableContainer>
          </Scrollbar>
          
        </Card>
        </Container>
        </Page>
  )}
