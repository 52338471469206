
// material
import { Card, Box } from '@mui/material';
//
import { Grid, Container, Typography } from '@mui/material';
import SearchNotFound from 'src/components/SearchNotFound';
import { filter } from "lodash";
import { useState } from "react";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useUserContext } from 'src/context/appContext'; 
import React, { useEffect } from "react";
import ChartModal from 'src/components/charts/ChartModal';
// material

import Label from 'src/components/Label';

// import {

//   Table,
//   TableRow,
//   TableHead,
//   TableBody,
//   TableCell,
//   Typography,
//   TableContainer,
// } from "@mui/material";


import {
  InstalledVillage,
  UninstalledVillage,
  TotalVillage
} from '../dash';

///////api


import axios from 'axios';



// components
import Scrollbar from 'src/components/Scrollbar';



import {UserListToolbar } from '../user';


// ----------------------------------------------------------------------



//////api calling





export default function AppWebsiteVisits() {

  const { user,cookies } = useUserContext();

 
  const [villageCount,setVillageCount]=useState({});

  const getDataFromApi= async ()=>{
    await axios.get(`http://51.158.109.251:9001/api/villagecount`)
      .then(res => {
         const villageData = res.data;  
         setVillageCount(villageData);
        })
        .catch((err) => console.log(err));
  }
  

 
useEffect(() => {
 
 
if(cookies.get('userId'))
{
  getDataFromApi();
}

  
});






///////////////////////////////////////////////////////////////

//   const { user,firestoreSnapshot, getDataFromFirebase } = useUserContext();

// const [orderBy, setOrderBy] = useState("name");
// const [order, setOrder] = useState("asc");
// const [filter, setFilter] = React.useState('all');

 

  

// let items = firestoreSnapshot;
// const isUserNotFound = items.length === 0;
// const [filteredUsers,setFilteredUsers]=useState(items);
// const [selected, setSelected] = useState([]);
// const [filterName, setFilterName] = useState("");
// items = applySortFilter(
//   filteredUsers,
//   getComparator(order, orderBy),
//   filterName
// );

// const handleChange = (event) => {
//   setFilter(event.target.value);
// };
// useEffect(() =>{
//   if (filter!=="all") {
//     items = firestoreSnapshot.filter((curElem) => {
//       return curElem.boringType === filter;
//     });
//   }
//   else{
//     items = firestoreSnapshot.filter((curElem) => {
//       return curElem;
//     });
//   }
//   setFilteredUsers(items)
// },[filter]);



// const handleFilterByName = (event) => {
//   setFilterName(event.target.value);
// };

  // useEffect(() => {
  //   if (firestoreSnapshot.length === 0 && user) {
  //     getDataFromFirebase();
  //   }
  //   setFilteredUsers(firestoreSnapshot);
  // }, [firestoreSnapshot,user,getDataFromFirebase]);

  let motor = false;



  return(
    <>
    <Box sx={{ pb: 3 }} mt={3}>
    <Typography variant="h4"> Automation </Typography>
  </Box>
  <Box mt={8}>
    
    <Grid container spacing={4} >
          <Grid item xs={12} sm={6} md={4}>
            <TotalVillage total={villageCount?.totalVillage} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InstalledVillage total={villageCount?.installed} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <UninstalledVillage total={villageCount?.uninstalled}  />
          </Grid>
          
        </Grid>
    {/* <Box 
      sx={{
       display:"flex",
        justifyContent:"space-around"
      }}>
    <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

    
        
        
        <FormControl required sx={{ m: 2.5, minWidth: 120 }} >
        <InputLabel id="demo-simple-select-readonly-label">Select </InputLabel>

        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={filter}
          label="Filter"
          defaultValue={"all"}
          onChange={handleChange}
        >
          <MenuItem value={"all"}>All</MenuItem>
          <MenuItem value={"oht"}>Oht</MenuItem>
          <MenuItem value={"sumpwell"}>Sumpwell</MenuItem>
          <MenuItem value={"direct"}>Direct</MenuItem>
        </Select>
      </FormControl>
       
        
       

    </Box>
    
    
          
          <Scrollbar
          sx={{
            height: "370px",
            "& .simplebar-content": {
              height: "370px",
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
            <TableContainer sx={{ minWidth: 500 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                  
                  {TABLE_HEAD.map((tableCell)=>(
                    <TableCell
                    key={tableCell.id}
                    >
                      {tableCell.label}

                  </TableCell>
                  ))}

                  </TableRow>
                </TableHead>

                <TableBody>
                  {itemsFromApp
                    
                    .map((row) => {
                      const {
                        dId,
                        name,
                        waterLevel,
                        mState,
                        deployed,
                        totalRunningTime,
                        recentUpdate,
                      } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;
                      if (mState !== "0") {
                        motor = true;
                      } else {
                        motor = false;
                      }
                      return (
                        <TableRow
                          hover
                          key={dId}
                          tabIndex={1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          
                        >
                          
                          <TableCell component="th" scope="row">
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">{waterLevel}</TableCell>

                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={motor ? "success" : "error"}
                            >
                              {motor ? "ON" : "OFF"}
                            </Label>
                          </TableCell>
                          <TableCell align="left">{deployed}</TableCell>
                          <TableCell align="left">{totalRunningTime} Min.</TableCell>
                          <TableCell component="th" scope="row" >
                            <Typography variant="subtitle2">
                            { dateGenerate(recentUpdate)}
                            </Typography>
                          </TableCell>
                          
                           <TableCell align="left">
                           <ChartModal  id={dId} data={row}/>
                          </TableCell>

                          
                        </TableRow>
                      );
                    })}
                    
                  
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                
                
              </Table>
            </TableContainer>
          </Scrollbar> */}
          
        </Box>
        </>
  )}
