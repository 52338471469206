import  React,{useEffect,useState} from 'react';

import Typography from '@mui/material/Typography';

 import EcportExcel from '../components/ExportExcel'
import Page from "../components/Page";
import { 
    Stack,
    Container,
  } from "@mui/material";
  import ReactHTMLTableToExcel from "react-html-table-to-excel";

export default function DownloadReport() {


 

return(
    <Page title="Report | PHED">
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={0}
      >
        <Typography variant="h5" gutterBottom>
        <div>
        <ReactHTMLTableToExcel
          className="download-table-xls-button btn btn-success mb-3"
          table="emp"
          filename="ReportExcel"
          sheet="Sheet"
          buttonText="Export"
        />
      </div>
        </Typography>
      </Stack>
        <EcportExcel/>
      </Container>
      </Page>
)

}