import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import DashboardApp from './pages/DashboardApp';
import ContractorWise from './pages/contractorWise';
import VillageWise from './pages/villageWise';
import Blog from './pages/Report';
import User from './pages/blockWise';
import NotFound from './pages/Page404';
import Help from './pages/help';
import Login from './pages/Login';
import DownloadReport from './pages/downloadReport';

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/dashboard/home" replace /> },
        { path: '/dashboard', element: <DashboardApp /> },
      
        { path: 'home', element: <DashboardApp /> },
        { path: 'villageWise', element: <VillageWise /> },
        { path: 'blockWise/:block', element: <User /> },
        { path: 'contractorWise', element: <ContractorWise /> },
        { path: 'report/:report', element: <Blog /> },
        { path: 'downloadreport/', element: <DownloadReport /> },
        { path: 'help', element: <Help /> }
        
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard/home" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
