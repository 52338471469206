
// material
import { Card, TextField,Stack  } from '@mui/material';
// components
import Page from '../components/Page';
import { Icon } from '@iconify/react';
import email from '@iconify/icons-eva/email-outline';
import location from '@iconify/icons-eva/map-outline';
import phoneFill from '@iconify/icons-eva/phone-outline'
import facebook from '@iconify/icons-eva/facebook-outline'
import whatsAppOutlined from '@iconify/icons-ant-design/whats-app-outlined'
import twitterOutlined from '@iconify/icons-ant-design/twitter-outlined';
import linkedinOutlined from '@iconify/icons-ant-design/linkedin-outlined';
import instagramOutlined from '@iconify/icons-ant-design/instagram-outlined';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import "./page.css"
import { LoadingButton } from '@mui/lab';

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: "90%",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    
     margin: theme.spacing(2, 0, 2, 2)


  }));

  const getIcon = (name) => <Icon icon={name} width={22} height={25} />;

export default function Help() {


    const navigate = useNavigate();
  
    const RegisterSchema = Yup.object().shape({
      firstName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('First name required'),
      
      email: Yup.string().email('Email must be a valid email address').required('Email is required'),
      mobile: Yup.string().required('Mobile is required'),
      textarea: Yup.string().notRequired('Please fill the text')
    });
  
    const formik = useFormik({
      initialValues: {
        firstName: '',
        mobile: '',
        email: '',
        textarea: 'text'
      },
      validationSchema: RegisterSchema,
      onSubmit: () => {
        navigate('/dashboard/home', { replace: true });
        console.log(JSON.stringify(formik.values, null, 2));
      }
      
    });
  
    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  










    return (
        <Page title="Contact Us | PHED">
          
        <SectionStyle>
            <div id="contact-container">
                <div className='contact-info'>
                    <h4>
                        Get in Touch
                    </h4>
                    <p>We love to hear from you.</p>
                    
                    <div className='icon-text'>
                        <div className='icons'>{ getIcon(email)}</div>
                        <div className='icon-text-area'>
                            <h4>Chat to Us</h4>
                                <p>Email us  at: <br />
                                <a href='mailto:telexaindia@gmail.com'>telexaindia@gmail.com</a></p>
                        </div>
                    </div>
                    <div className='icon-text'>
                        <div className='icons'>{ getIcon(location)}</div>
                        <div className='icon-text-area'>
                            <h4>Office</h4>
                                <p> locate us : <br /> <a href='https://goo.gl/maps/MjTGyn8oedE1Bi5U6' target="_blank" rel="noreferrer">34/2-C New Palasia,M.P</a></p>
                        </div>
                    </div>
                    <div className='icon-text'>
                        <div className='icons'>{ getIcon(phoneFill)}</div>
                        <div className='icon-text-area'>
                            <h4>Phone</h4>
                                 <p>Get the mobile at: <br /> <a href='tel:+91 8989954333'>+91 8989954333</a> <br /> <a href='tel:+91 8989054333'>+91 8989054333</a></p>
                        </div>
                    </div>
                    <div className='social-media'>
                        <a href="https://www.facebook.com/telexaindia/" className='icon-circle'  target="_blank" rel="noreferrer">
                            <i className='icon'>{ getIcon(facebook)}</i>
                        </a>
                        <a href="https://api.whatsapp.com/send/?phone=%2B918962307685&text&app_absent=0" className='icon-circle' target="_blank" rel="noreferrer">
                            <i className='icon'>{ getIcon(whatsAppOutlined)}</i>
                        </a>
                        <a href="https://twitter.com/TelexaL" className='icon-circle' target="_blank" rel="noreferrer">
                            <i className='icon'>{ getIcon(twitterOutlined)}</i>
                        </a>
                        <a href="https://www.linkedin.com/in/telexa/" className='icon-circle' target="_blank" rel="noreferrer">
                            <i className='icon'>{ getIcon(linkedinOutlined)}</i>
                        </a>
                        <a href="https://www.instagram.com/telexaindia/" className='icon-circle' target="_blank" rel="noreferrer">
                            <i className='icon'>{ getIcon(instagramOutlined)}</i>
                        </a>
                    </div>
                </div>
                <div className='contact-form'>
                <FormikProvider value={formik}>
                <Form  className='form-contactUs' autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
          
                    <div className='form-group'>
                    <h4>Level Up Your Brand</h4>
                    <p>You can reach us anytime via <a href='mailto:info@telexa.in'>info@telexa.in</a></p>
                    
                        <TextField
                            fullWidth
                            label="First name"
                            {...getFieldProps('firstName')}
                            error={Boolean(touched.firstName && errors.firstName)}
                            helperText={touched.firstName && errors.firstName}
                            className="forms-input"
                        />
                        <TextField
                            fullWidth
                            autoComplete="username"
                            type="email"
                            label="Email address"
                            {...getFieldProps('email')}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                            className="forms-input"
                        />
                        <TextField
                            fullWidth
                            label="Phone No: "
                            {...getFieldProps('mobile')}
                            error={Boolean(touched.mobile && errors.mobile)}
                            helperText={touched.mobile && errors.mobile}
                            className="forms-input"
                        />
                        
                        <TextField
                            id="outlined-multiline-static"
                            label="How can we Help?"
                            multiline
                            rows={2}
                            defaultValue="Write to us"
                            {...getFieldProps('textarea')}
                                error={Boolean(touched.textarea && errors.textarea)}
                                helperText={touched.textarea && errors.textarea}
                                className="forms-input"
                        />
                        
                        

                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                        >
                            Get Started
                        </LoadingButton>
                        

                    </div>
                    </Stack>
                    </Form>
                </FormikProvider>
                
                </div>
            </div>
          
        </SectionStyle>
      
        </Page>
      );
};


