import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCLJ8HFCEJ9u7JzDePN-BZsvVF7ipU0vg4",
  authDomain: "telexa-watertank-f100d.firebaseapp.com",
  databaseURL: "https://telexa-watertank-f100d-default-rtdb.firebaseio.com",
  projectId: "telexa-watertank-f100d",
  storageBucket: "telexa-watertank-f100d.appspot.com",
  messagingSenderId: "439051992732",
  appId: "1:439051992732:web:520740e586730a86123759",
  measurementId: "G-97K8Q30ZLR",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestoreDatabaseConnection = getFirestore(app);