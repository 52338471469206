import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Drawer,
  Typography,
  Avatar,
  Stack,
} from "@mui/material";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { MHidden } from "../../components/@material-extend";
import sidebarConfig from "./SidebarConfig";
import account from "../../_mocks_/account";
import {  useNavigate } from "react-router-dom";
import { useUserContext } from "../../context/appContext";
import CarouselItem from "src/components/CarouselItem";
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};


// ----------------------------------------------------------------------


export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {

  const { user,getAllBlock,blockinfo,getAllVillage,villageinfo,cookies} = useUserContext();
  const navigate = useNavigate();
  useEffect(()=>{
    if(!cookies.get('userId'))
    {
      navigate("/login", { replace: true });
    }

    if(cookies.get('userId') && blockinfo.length==0)
    {
      getAllBlock();
    }
    if(cookies.get('userId') && villageinfo.length==0)
    {
      getAllVillage();
    }


  })
//////block//////

  var childrenDataBlock=[];
  blockinfo.map((block,index)=>{
    childrenDataBlock[index]={"title":block.block.toUpperCase(),"path":`/dashboard/blockWise/${block.block}`}
  });
  
  sidebarConfig[2].children=childrenDataBlock;
/////report///////

var childrenDatarReport=[];
  childrenDatarReport[0]={"title":'Newly Added Villages',"path":`/dashboard/report/newvillage`}
  childrenDatarReport[1]={"title":'Recently Running Motors',"path":`/dashboard/report/report1`}
  childrenDatarReport[2]={"title":'Motors Properly Running last 24H',"path":`/dashboard/report/report2`}
  childrenDatarReport[3]={"title":'Running Less Than 10M in last 24H',"path":`/dashboard/report/report3`}
  childrenDatarReport[4]={"title":'Recharge required/Bypass/Other Fault',"path":`/dashboard/report/report4`}
  childrenDatarReport[5]={"title":'Download Reports',"path":`/dashboard/downloadreport`}
  sidebarConfig[4].children=childrenDatarReport;
  
  

  const { pathname } = useLocation();


  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
          <Logo />
        </Box>
      </Box>

      {/* <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {account.displayName}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {account.role}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box> */}
      <CarouselItem/>

      <NavSection navConfig={sidebarConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 5 }}> 
       <Stack
          alignItems="center"
          spacing={3}
          sx={{
            borderRadius: 2,
            position: 'relative'
            
          }}
        >
          <a href="https://telexa.in"
            target="_blank" rel="noreferrer">
          <Box
            component="img"
            src="/static/telexa.png"
           
            sx={{ width: 100, position: 'absolute', top: -65  ,left:55}}
          />
          </a>
          <Box sx={{ textAlign: 'center',position: 'absolute',top: -60 ,left:-10 }}>
            
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Copyright © | 2022 Telexa Technology | All Rights Reserved
            </Typography>
          </Box>
          

          

          
            
        </Stack> 
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
