import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";

import { Carousel } from "@trendyol-js/react-carousel";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Backdrop from "@mui/material/Backdrop";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import { Rerousel } from "rerousel";

import axios from "axios";
import { useUserContext } from "src/context/appContext";
import { Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export default function CarouselItem(props) {
  const { user,cookies } = useUserContext();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [villageinfo, setVillageinfo] = useState([]);

  const [report1, setReport1] = useState(0);
  const [report2, setReport2] = useState(0);
  const [report3, setReport3] = useState(0);
  const [report4, setReport4] = useState(0);

  const getDataFromApi = async () => {
    await axios
      .get(`http://51.158.109.251:9001/api/villagerecord/`, {params: { userId:cookies.get('userId') }})
      .then((res) => {
        setVillageinfo(res.data);
      })
      .catch((err) => console.log(err));
  };

  const [blockinfo, setBlockInfo] = useState([]);
  const getAllBlock = async () => {
    await axios
      .get(`http://51.158.109.251:9001/api/villagerecord/blockwiseblock`, {params: { userId:cookies.get('userId') }})
      .then((res) => {
        const blockData = res.data;

        setBlockInfo(blockData);
      })
      .catch((err) => console.log(err));
  };

  const [boringTypeCount, SetBoringTypeCount] = useState([]);
  const getAllBoringType = async () => {
    await axios
      .get(`http://51.158.109.251:9001/api/villagerecord/boringTypeCount`, {params: { userId:cookies.get('userId') }})
      .then((res) => {
        const data = res.data;
     
        SetBoringTypeCount(data);
      })
      .catch((err) => console.log(err));
  };
  const [allBoringCount,setAllBoringCount]=useState(0);
  useEffect(() => {
    var c1 = 0;
    var c2 = 0;
    var c3 = 0;
    var c4 = 0;


   

    if (villageinfo.length === 0  && open) {
      getDataFromApi();
    }
    villageinfo.map((item) => {
      if ((item.updateAgo/1440) > 2) {
        c1++;
      } else if (item.mState === "1") {
        c2++;
      } else if (item.totalRunningTimeForColor < 10) {
        c3++;
      } else {
        c4++;
      }
    });
    setReport4(c1);
    setReport1(c2);
    setReport3(c3);
    setReport2(c4);

    if (blockinfo.length == 0   && open) {
      getAllBlock();
    }

    if (boringTypeCount.length == 0 && open) {
      getAllBoringType();
    }
    var count=0;
    boringTypeCount && boringTypeCount.map((item) => {
      count += item.boringCount;
    });
    setAllBoringCount(count)


    const intervalId = setInterval(() => { 
      if(cookies.get('userId') && open)
      {
        getDataFromApi();
        getAllBlock();
        getAllBoringType();
      }

    }, 60000)

    return () => clearInterval(intervalId); 

  }, [villageinfo, blockinfo, boringTypeCount,open]);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const ref = useRef(null);
  return (
    <div>
      <Button sx={{ ml: 6, mb: 2 }} onClick={handleOpen} variant="contained">
        Open Slider
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <HighlightOffIcon
              onClick={handleClose}
              fontSize="large"
              color="error"
              sx={{ ml: "99%", mt: "-5%" }}
            />
            <Rerousel itemRef={ref} stop={false}>
              <Card sx={{ minWidth: "100%", margin:"0 auto" }} ref={ref}>
                <CardContent sx={{margin:"0 auto"}}  align="center">
                  <Typography variant="h3" align="center">
                    Reports
                  </Typography>
                  <Grid container spacing={2}  sx={{width:"100%",margin:"0 auto"}} rowSpacing={6} columns={16} alignItems="center"
  justify="center">
                    <Grid item xs={8}  sx={{padding:"8px"}}>
                      <Item
                        style={{ color: "black", backgroundColor: "#90ee90" }}
                      >
                        <h3>Recently Running Motors</h3>
                        <h5>village : {report1}</h5>
                      </Item>
                    </Grid>
                    <Grid item xs={8}  sx={{padding:"8px"}}>
                      <Item
                        style={{ color: "black", backgroundColor: "#f7f2f2" }}
                      >
                        <h3>Motors Properly Runningin last 24H</h3>
                        <h5>village : {report2}</h5>
                      </Item>
                    </Grid>
                    <Grid item xs={8}  sx={{padding:"8px"}}>
                      <Item
                        style={{ color: "black", backgroundColor: "#e86464" }}
                      >
                        <h3>Running Less Than 10M in last 24 H</h3>
                        <h5>village : {report3}</h5>
                      </Item>
                    </Grid>
                    <Grid item xs={8}  sx={{padding:"8px"}}>
                      <Item
                        style={{ color: "black", backgroundColor: "#ffff66" }}
                      >
                        <h3>Recharge required/Bypass/Other Fault</h3>
                        <h5>village : {report4}</h5>
                      </Item>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card sx={{ minWidth: "100%", margin:"0 auto" }}>
                <CardContent sx={{ml:5,mr:5}} align="center">
                  <Typography variant="h3" align="center">
                    Block Information
                  </Typography>
                  <Grid container spacing={2} sx={{width:"100%",margin:"0 auto"}} rowSpacing={6} columns={16} alignItems="center"
  justify="center">
                    {blockinfo &&
                      blockinfo.map((block) => {
                        return (
                          <Grid  sx={{padding:"8px"}} item xs={8} key={block.blockName}>
                            <Item
                              style={{
                                color: "black",
                                backgroundColor: "#90ee90",
                              }}
                            >
                              <h3>Block : {block.block}</h3>
                              <h5>village :{block.villageCount}</h5>
                            </Item>
                          </Grid>
                        );
                      })}
                  </Grid>
                </CardContent>
              </Card>
              <Card sx={{ minWidth: "100%", margin:"0 auto" }} >
                <CardContent sx={{ml:5 , mr:5} } align="center">
                  <Typography variant="h3" align="center">
                   Supply Type
                  </Typography>
                  <Grid container spacing={2}  sx={{width:"100%",margin:"0 auto"}} rowSpacing={6} columns={16} alignItems="center"
  justify="center">
                    <Grid item xs={8}  sx={{padding:"8px"}}>
                      <Item
                        style={{
                          color: "black",
                          backgroundColor: "#90ee90",
                        }}
                      >
                        <h3>
                          {'All'} : {allBoringCount}
                        </h3>
                       
                      </Item>
                    </Grid>
                    {boringTypeCount &&
                      boringTypeCount.map((boringtype) => {
                                   if(boringtype.boringType!=null)
                                   {
                        return (
                          <Grid item xs={8} sx={{padding:"8px"}}  key={boringtype.boringType} > 
                            <Item
                              style={{
                                color: "black",
                                backgroundColor: "#90ee90",
                              }}
                            >
                              <h3>
                                {boringtype.boringType.toUpperCase()} : {boringtype.boringCount}
                              </h3>
                              {/* <h5>
                            village :{block.villageCount}
                          </h5> */}
                            </Item>
                          </Grid>
                        );
                                   }
                      })}
                  </Grid>
                </CardContent>
              </Card>
            </Rerousel>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
