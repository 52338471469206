// material
import { Card, Box, CircularProgress } from "@mui/material";
//
import SearchNotFound from "src/components/SearchNotFound";
import { filter } from "lodash";
import { useState } from "react";

import { useUserContext } from "src/context/appContext";
import React, { useEffect } from "react";
import ChartModal from "src/components/charts/ChartModal";
// material
import Page from "../components/Page";
import Label from "src/components/Label";

import {
  Table,
  Stack,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  Container,
  TableContainer,
} from "@mui/material";

///////api

import axios from "axios";

// components
import Scrollbar from "src/components/Scrollbar";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "waterLevel", label: "Tank", alignRight: false },
  { id: "motor", label: "Motor", alignRight: false },
  { id: "source", label: "Source", alignRight: false },
  { id: "runTime", label: "Run Time", alignRight: false },
  { id: "boringType", label: "Boring Type", alignRight: false },
  { id: "lpcd", label: "LPCD", alignRight: false },
  { id: "lpm", label: "LPM", alignRight: false },
  { id: "population", label: "Population", alignRight: false },
  { id: "recentUpdate", label: "Last Update", alignRight: false },
  { id: "operatorName", label: "Operator Name", alignRight: false },
  { id: "operatorMobile", label: "Operator Number", alignRight: false },
  { id: "contractorName", label: "Contractor Name", alignRight: false },
  { id: "block", label: "Block", alignRight: false },
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }

  return stabilizedThis.map((el) => el[0]);
}

export default function EcportExcel(props) {
  const { user } = useUserContext();

  const [villageinfo, setVillageinfo] = useState([]);

  const getDataFromApi = async () => {
    await axios
      .get(`http://51.158.109.251:9001/api/allVillageData/`)
      .then((res) => {
        const villageData = res.data;
        setVillageinfo(villageData);
      })
      .catch((err) => console.log(err));
  };

  let itemsFromApp = villageinfo;
  const isUserNotFound = itemsFromApp.length === 0;

  useEffect(() => {
    if (villageinfo.length === 0 && user) {
      getDataFromApi();
    }
  }, [user, villageinfo]);

  let motor = false;

  function dateGenerate(e) {
    // var t=new Date(e);
    // return t.toLocaleDateString()+" | "+t.toLocaleTimeString()

    const str =
      e.split(" ")[0] + " | " + e.split(" ")[1] + " " + e.split(" ")[2];
    if (str === "Invalid | Date undefined") return "No Record Found";
    else return str;
  }

  return (
    <Card>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
        }}
      ></Box>

      <Scrollbar
        sx={{
          height: "400px",
          "& .simplebar-content": {
            height: "400px",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <TableContainer sx={{ minWidth: 700 }}>
          <Table stickyHeader aria-label="sticky table" id="emp" >
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((tableCell) => (
                  <TableCell key={tableCell.id}>{tableCell.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {villageinfo.map((row) => {
                const {
                  dId,
                  name,
                  mState,
                  waterLevel,
                  boringType,
                  deployed,
                  lpm,
                  contractorName,
                  population,
                  operatorName,
                  operatorMobile,
                  block,
                  contractorEmail,
                  updateAgo,
                  recentUpdate,
                  totalRunningTime,
                 
                } = row;

                if (mState !== "0") {
                  motor = true;
                } else {
                  motor = false;
                }
                return (
                  <TableRow hover key={dId} tabIndex={1}>
                    <TableCell component="th" scope="row">
                      <Typography variant="inherit" noWrap>
                        {name}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">{waterLevel}%</TableCell>

                    <TableCell align="left">
                      <Label
                        variant="ghost"
                        color={motor ? "success" : "error"}
                      >
                        {motor ? "ON" : "OFF"}
                      </Label>
                    </TableCell>

                    <TableCell align="left">{deployed}</TableCell>
                    <TableCell align="left"><Typography variant="inherit"   noWrap>{totalRunningTime} Min.</Typography></TableCell>
                  
                    <TableCell component="th" scope="row">
                      <Typography variant="inherit">{boringType}</Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                    <Typography variant="inherit">
                        {Number(
                          (
                            (totalRunningTime * lpm) /
                            population
                          ).toFixed(2)
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                    <Typography variant="inherit">{lpm}</Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography variant="inherit">{population}</Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography variant="inherit" noWrap>{recentUpdate}</Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography variant="inherit" noWrap>{operatorName}</Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography variant="inherit" noWrap>{operatorMobile}</Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography variant="inherit" noWrap>{contractorName}</Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography variant="inherit" noWrap>{block}</Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}
