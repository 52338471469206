import React from "react";
import Paper from "@material-ui/core/Paper";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  WeekView,
  Toolbar,
  AppointmentTooltip,
  DateNavigator,
  Appointments,
  AllDayPanel,
} from "@devexpress/dx-react-scheduler-material-ui";
import {
  useState,
  useCallback,
  useEffect,
  createContext,
  useContext,
} from "react";
import { useUserContext } from "src/context/appContext";

//////////////////api////////////////////
import axios from "axios";
import { DateTimePicker } from "@mui/lab";

var today = new Date();
var dd = String(today.getDate()).padStart(2, "0");
var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
var yyyy = today.getFullYear();
today = yyyy + "-" + mm + "-" + dd;

const TooltipLayout = (props) => {
  return (
    <AppointmentTooltip.Layout
      {...props}
      useLayerForClickAway={false}
      style={{ pointerEvents: "none", top: "20px" }}
    />
  );
};

const dateFormating = (str) => {
  if(str){
    str=str.toString();
    var x = str?.split(" ");
    var t=x[1].split(':');
    var s = x[0];
    var st = s.split("-");
    var date=new Date(st[2],st[1],st[0],t[0],t[1])
   
    var f=(st[2] + "-" + st[1] + "-" + st[0] + "T" + t[0]+':'+t[1])
    return new Date(f);
  }
 
};
export default function SchedulerGraph(props) {
  const dateandtimerange = [];

  const [villageinfo, setVillageinfo] = useState([]);

  const getDataFromApi = async () => {
    await axios
      .get(`http://51.158.109.251:9001/api/villagerecord/graph`, {
        params: { dId: props.id },
      })
      .then((res) => {
        const villageData = res.data;

        setVillageinfo(villageData);
      })
      .catch((err) => console.log(err));
  };

  // const { user, firestoreSnapshot, getDataFromFirebase } = useUserContext();
  // useEffect(() => {
  //   if (firestoreSnapshot.length === 0 && user) {
  //     getDataFromFirebase();
  //     getDataFromApi();
  //   }
  // }, [firestoreSnapshot, user, getDataFromFirebase]);
  // const data = [...new Set(firestoreSnapshot.map((datas) => datas))];
  // const id = props.id;

  // var updatedItems1 = data.filter((curElem) => {
  //   return curElem.dId === id;
  // });

 
   const { user } = useUserContext();
  // getDataFromApi();

  useEffect(() => {
    if (villageinfo.length === 0 ) {
      getDataFromApi();
    }
  
  }, [ villageinfo]);

  var updatedItems = villageinfo;

  var start = updatedItems.map(function (el) {
    return el.startTime;
  });

  var end = updatedItems.map(function (el) {
    return el.endTime;
  });
  

  if (updatedItems && start[0] && end[0]) {
    //console.log("end",end[0].length)
    let i = 0;
    for (let x in end[0]) {
      let timeframe = {
        title: "",
        startDate: dateFormating(start[0][i]),
        endDate: dateFormating(end[0][i]),
        id: i,
        location: "",
      };

      dateandtimerange.push(timeframe);
      i++;
    }
    if(start[0].length > end[0].length && updatedItems[0].mState=='1')
    {
      let timeframe = {
        title: "",
        startDate: dateFormating(start[0][i]),
        endDate:new Date(),
        id: i,
        location: "",
      };
      dateandtimerange.push(timeframe);
    }
    
   
   

    // for (var i = 0; i < end[0].length; i++) {
    //   let timeframe = {
    //     title: "",
    //     startDate: new Date(start[0][i]),
    //     endDate: new Date(end[0][i]),
    //     id: i,
    //     location: "",
    //   };
    //   dateandtimerange.push(timeframe);
    // }
  }

  //TOOLTIP-----------
  const [tooltipVisible, setTooltipVisibility] = useState(false);
  const [appointmentMeta, setAppointmentMeta] = useState({
    target: null,
    data: {},
  });
  const Appointment = useCallback(
    ({ onClick, data, ...restProps }) => {
      return (
        <Appointments.Appointment
          data={data}
          onClick={onClick}
          {...restProps}
          onMouseEnter={({ target }) => {
            setTooltipVisibility(true);
            setAppointmentMeta({ target, data });
          }}
          onMouseLeave={() => {
            setTooltipVisibility(false);
            setAppointmentMeta({ target: null, data: {} });
          }}
        />
      );
    },
    [setTooltipVisibility, setAppointmentMeta]
  );

  //Tooltip end-------------------
  return (
    <div>
      <Paper>
        <Scheduler data={dateandtimerange} height={500}>
          <ViewState defaultCurrentDate={Date()} />
          <WeekView startDayHour={0} endDayHour={24} cellDuration={120} />
          <Toolbar />
          <DateNavigator />
          <Appointments appointmentComponent={Appointment} />
          <AppointmentTooltip
            visible={tooltipVisible}
            onVisibilityChange={setTooltipVisibility}
            appointmentMeta={appointmentMeta}
            onAppointmentMetaChange={setAppointmentMeta}
            layoutComponent={TooltipLayout}
          />
          <AllDayPanel />
        </Scheduler>
      </Paper>
    </div>
  );
}
