// ----------------------------------------------------------------------

const account = {
  displayName: "PHED Indore",
  email: "",
  photoURL: "/static/avatar.webp",
  logoURL:"/static/logo.png"
};

export default account;
