import { Icon } from '@iconify/react';
import houseIcon from '@iconify/icons-eva/home-outline';
import holidayVillage from '@iconify/icons-fontisto/holiday-village';
import peopleFill from '@iconify/icons-eva/people-fill';
import blockFill from '@iconify/icons-eva/options-outline';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import questionMarkFill from '@iconify/icons-eva/question-mark-circle-fill'




// ----------------------------------------------------------------------




const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

// const blockList=[{"title":"Depalpur","path":'/dashboard/blockWise/depalpur'},{"title":"Indore","path":"/dashboard/blockWise/indore"},{"title":"Mhow","path":"/dashboard/blockWise/mhow"},{"title":"Sanwer","path":"/dashboard/blockWise/sanwer"}]

const sidebarConfig = [
  {
    title: 'home',
    path: '/dashboard/home',
    icon: getIcon(houseIcon)
  },
  {
    title: 'Village Wise',
    path: '/dashboard/villageWise',
    icon: getIcon(holidayVillage)
  },
  {
    title: 'Block Wise',
    path: '/dashboard/blockWise',
    icon: getIcon(blockFill),
    children: [],
  },
  {
    title: 'Contractor Wise',
    path: '/dashboard/contractorWise',
    icon: getIcon(peopleFill)
  },
  {
    title: 'View Report',
    path: '/dashboard/report',
    icon: getIcon(fileTextFill)
  },
  {
      title: 'Help',
      path: '/dashboard/help',
      icon: getIcon(questionMarkFill)
    },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon(lockFill)
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill)
  // }
];

export default sidebarConfig;
